@font-face {
  font-family: "LINE Seed Sans";
  font-weight: 100;
  src: url("/fonts/LINESeedSans_W_Th.woff2") format("woff");
}
@font-face {
  font-family: "LINE Seed Sans";
  font-weight: 200;
  src: url("/fonts/LINESeedSans_W_Rg.woff2") format("woff");
}
@font-face {
  font-family: "LINE Seed Sans";
  font-weight: 700;
  src: url("/fonts/LINESeedSans_W_Bd.woff2") format("woff");
}
@font-face {
  font-family: "LINE Seed Sans";
  font-weight: 800;
  src: url("/fonts/LINESeedSans_W_XBd.woff2") format("woff");
}
@font-face {
  font-family: "LINE Seed Sans";
  font-weight: 900;
  src: url("/fonts/LINESeedSans_W_He.woff2") format("woff");
}

html {
  overflow-x: hidden;
  overflow-y: hidden;
}

body {
  overflow-x: auto;
  overflow-y: auto;
  user-select: none;
  -webkit-user-select: none;
  overscroll-behavior-x: none;
  overscroll-behavior-y: none;
}
